import {Routes, Route} from "react-router-dom";
import AuthLayout from "./Components/Layouts/Auth";
import DashboardLayout from "./Components/Layouts/Dashboard";
import Login from "./Pages/Auth/Login";
import Register from "./Pages/Auth/Register";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import ResetPassword from "./Pages/Auth/ResetPassword";
import Onboard from "./Pages/Auth/Onboard";
import Home from "./Pages/Dashboard/Home";
import Favorites from "./Pages/Dashboard/Favorites";
import History from "./Pages/Dashboard/History";
import Payments from "./Pages/Dashboard/Payments";
import Profile from "./Pages/Dashboard/Profile";
import Faqs from "./Pages/Dashboard/Faqs";
import Help from "./Pages/Dashboard/Help";
import PageNotFound from "./Components/Layouts/PageNotFound";
import LinkedInConnect from "./Pages/Auth/LinkedInConnect";
import Download from "./Pages/Auth/Download";

import Zendesk from "react-zendesk";
const ZENDESK_KEY = "640e1b7e-6870-47ea-a692-0ec5cc1b6fed";

const setting = {
    color: {
      theme: "#000"
    },
    launcher: {
      chatLabel: {
        "en-US": "Need Help"
      }
    },
    contactForm: {
      fields: [
        { id: "description", prefill: { "*": "My pre-filled description" } }
      ]
    }
  };  

const App = () => {
    return (
      <>
        <Zendesk
          defer
          zendeskKey={ZENDESK_KEY}
          {...setting}
          onLoaded={() => console.log("is loaded")}
        />
        <Routes>
          <Route path="/download" element={<Download />} />
          <Route path="/" element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/linkedin" element={<LinkedInConnect />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/onboard" element={<Onboard />} />
          </Route>
          <Route path="/" element={<DashboardLayout />}>
            <Route index element={<Home />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/history" element={<History />} />
            <Route path="/payment" element={<Payments />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/help" element={<Help />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </>
    );
};

export default App;
