import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { boolean, object, string } from "yup";
import { useAuthProviderContext } from "../../Providers/AuthProvider";
import Logo from "../../Components/Logo";
import Button from "../../Components/Button";
import FormField from "../../Components/Forms/FormField";
import { handleLogin, handleGoogleLogin, getLinkedInAuth } from "../../services/auth";
import IconGoogle from "../../Components/Icons/IconGoogle";
import { getCookie } from "../../Providers/AuthProvider";
import { axiosInterceptor } from "../../AxiosInstance";

const schema = object({
  email: string().email().required("The field is required"),
  password: string().required("The field is required"),
  agrement: boolean().oneOf([true], "The field is required").required("The field is required")
}).required();

const Login = () => {
  const [submitBtnloading, setSubmitBtnloading] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    text: "",
    cls: "text-primaryColor4",
  });
  const { auth, setAuth } = useAuthProviderContext();
  const navigate = useNavigate();

  console.log("login page");
 


  // useEffect(() => {
  //   const authStorageToken = localStorage.getItem("authStorageToken");
  //   if (authStorageToken) {
  //     localStorage.removeItem("authStorageToken");
  //     // document.cookie =
  //     //   "connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //     //window.location.reload(true);
  //   }
  // }, []);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // if (auth.user) return navigate("/");

  const login = async (data) => {
    setSubmitBtnloading(true);
    console.log("TEST");
    try {
      //await axiosInterceptor("");
      console.log("START");
      const res = await handleLogin(data);
      console.log(res);
      if (res.data.success) {
        setAuth((prev) => ({ ...prev, token: res.data.token }));
        setTimeout(() => {
          navigate("/linkedin");
          //navigate("/download");
        }, 500);
        
      } else {
        setSubmitBtnloading(false);
        setApiResponse({
          text: "Invalid login details",
          cls: "text-primaryColor4",
        });
        console.error(res.data.message);
      }
    } catch (err) {
      console.log("TEST");
      console.log(err);
      setSubmitBtnloading(false);
      setApiResponse({
        text: "Invalid login details",
        cls: "text-primaryColor4",
      });
      console.error(err);
    }
  };

  const loginGoogle = async () => {
   
   // temp hardcode, replace or remove  

    // const data = {from: "web", refferal_id: ""};
   
    try {
      const res = await handleGoogleLogin({from: "web", refferal_id: ""});
     
      if (res.data.authURL) {
        window.location.href = res.data.authURL;
      } else {
        setApiResponse({
          text: "Invalid login details",
          cls: "text-primaryColor4",
        });
        console.error(res.data.message);
      }
    } catch (err) {
      console.log("TEST");
      console.log(err);
      setApiResponse({
        text: "Invalid login details",
        cls: "text-primaryColor4",
      });
      console.error(err);
    }
  };

  return (
    <React.Fragment>
      <Logo />
      <p className="text-[14px] text-right">
        Dont have an account?{" "}
        <Link className="text-textBlue" to={"/register"}>
          Sign up
        </Link>
      </p>
      <form
          className="flex flex-col"
          onSubmit={handleSubmit((data) => {
            login(data);
          })}
        >
        <FormField
          errors={errors}
          register={register}
          id="email"
          placeholder="Email"
          ariaDescribedby={{
            id: "emailHelp"
          }}
        />
        <FormField
          errors={errors}
          register={register}
          id="password"
          placeholder="Password"
          type="password"
        />
        <Link
            className="text-[13px] text-right text-textBlue text-primaryColor1"
            to={"/forgot-password"}
          >
            Forgot Password?
        </Link>
        {/* <div className="or_divider">
          <div className="dots_line"></div>
          <p>OR</p>
          <div className="dots_line"></div>
        </div>
        <div 
            className="login_google"
            onClick={async (e) => {
              e.preventDefault();
              const isValid = await trigger("agrement");
              isValid && loginGoogle();        
            }}
        >
            <IconGoogle />
            <button type="button">Join with Google</button>
        </div> */}
        <FormField
          errors={errors}
          register={register}
          customClass="agrement-checkbox"
          id="agrement"
          label={true}
          text={<p>I accept the <a href="#">Terms of use</a> and <a href="#">Privacy Policy</a></p>}
          type="checkbox"
        />
        <Button
            text="Continue"
            classes="login-btn"
            submitBtnloading={{ loading: submitBtnloading, text: "Signing In" }}         
        />
        <div className="flex justify-center flex-col items-center">
          <p className={`font-bold ${apiResponse.cls}`}>{apiResponse.text}</p>
        </div>
      </form>
     
    </React.Fragment>
  );
};

export default Login;
